import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigateproService } from '../../Services/navigatepro.service'
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CartserviceService } from '../../Services/cartservice.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent  {
    @ViewChild('typeId') typeId: ElementRef;
    sizedata = [];
    orderitem = [];
    selectedItemforCart = [];
    Price: number;
    TPrice: number;
    Sname: string;
    Name: string;
    quantity: number = 0;

    //quantity= 1;
    loginDet: any;
    ProductID: any;
    Productdata: any;
    HomeUrl: any;
    arr: any = [];
    Itemarr: any = [];
   // Price: any;
    Discount: any;
    ProductName: any;
    Image: any;
    SchoolName: any;
    TypeName: any;
    //Name: any;
    PackageItems: any;

    ProductInfo: any;
    disprice: any;
  //  TPrice: any;
    gtprice: any;
    RProductdata: any;
    //sizedata: any;
   // sizedata: any = {};

    //selectedItemforCart: any = []
    cartItemList: any = []
    Productid: any;
    logedinUser: any;
    loginDetails: any;
    Cartimesforloginusers: any[];
   // Sname: any;
    message: any;
    beforePrice: any;
    Description: any;
    PackageDiscount: any;
        sizeitem: any;
    PakageName: any;
    GenderType: any;
    subscription: Subscription;
    itemsdata: any;
    addressId: any;
    AID: any;
    addressDetails: any;
    plus: boolean;
    cartItems: any;
    subTotal: number;
    orderno: any;
    val: number;
    PaymentType: any;
    n: any;
    Size: any;
    UPID: any; GrandTotal: any;
    //orderitem: { ProductName: string, Name: string, TypeID: string }[] = []; // Initialize orderitem array
    quantities: number[] = []; size: any;

    constructor(public activeroute: ActivatedRoute, public cartservice: CartserviceService, public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router) {

        debugger
        for (let i = 1; i <= 100; i++) {
            this.quantities.push(i);
        }
        this.Sname = this.activeroute.snapshot.paramMap.get('Package').replace(/\_/g, " ").trim();
        localStorage.setItem("Id", this.Sname);
        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getproducts();
                this.getitems();
            }
            else {

                this.Sname = res[0].Name;
                this.getproducts();
                this.getitems();
            }
        })
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            this.subscription = this.generalService.message$.subscribe(message => {
                this.message = message;
                // You can add logic to automatically dismiss messages after some time
                setTimeout(() => this.clearMessage(), 5000);
            });

            // this.ProductID = this.navigateservice.statusId;
        });
        this.cartservice.getProducts().subscribe(res => {
            this.cartItemList = res /*Getting current cart item lists*/
        })

    }
    clearMessage() {
        this.message = null;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {

        this.getitems();
        this.getRproducts();
        this.getCustomerAddress();
    }

    updatePrice() {
        debugger
        this.GrandTotal = this.TPrice * this.quantity
        localStorage.setItem("GrandTotal", this.GrandTotal);
    }
    getSelectedAddress(addressId: any) {
        this.AID = addressId;
        //console.log(addressId)
    }
    getitems() {
        var UploadFile = new FormData();
        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/GetPackageItems_based_on_packageid";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data.length === 0) {
                this.generalService.ShowAlert('No Items', 'No Items in this Package', 'error');
            } else {
                this.itemsdata = data;
                this.itemsdata.forEach(item => {
                    this.getsizes(item.TypeID);
                });
                console.log(this.itemsdata); // Verify itemsdata
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }

    getsizes(typeId: any) {
        var UploadFile = new FormData();
        UploadFile.append("Param", typeId);
        var url = "api/DressBox/Get_sizes_basedon_TypeId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data.length === 0) {
            //    this.generalService.ShowAlert('No products', 'No products in this school', 'error');
            } else {
                this.sizedata[typeId] = data;  // Ensure sizedata is an object
                console.log(`Sizes for TypeID ${typeId}:`, data);  // Debug log
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }



    selectListType(selectedName: string, item: any) {
        const selectedType = this.sizedata[item.TypeID].find(type => type.Name === selectedName);
        if (selectedType) {
            item.selectedSize = selectedName;
            // Store the selected size along with other item details
            this.orderitem.push({
                ProductName: item.ProductName,
                Name: selectedName,
                TypeID: selectedType.TypeID
            });
        } else {
            console.error('Selected type not found in sizedata:', selectedName);
        }
    }


    Addtocart(item: any) {
        debugger
        const selectedItem = this.orderitem.find(selected => selected.ProductName === item.ProductName);
        if (!selectedItem) {
            // Add the item to the orderitem array if it's not already selected
            this.orderitem.push({
                ProductName: item.ProductName,
                Name: item.selectedSize, // Assuming item.selectedSize contains the selected size
                TypeID: item.TypeID
            });

            // Show a success message or perform any other actions after adding to cart
            this.generalService.ShowAlert('Success', 'Item added to cart successfully', 'success');
        } else {
            // Show a warning or message indicating that the item is already in the cart
            this.generalService.ShowAlert('Warning', 'Item is already in the cart', 'warning');
        }
    }


   

    PlaceOrder() {
        debugger
        if (!this.loginDetails) {
            this.generalService.ShowAlert('Warning', 'You are not Logined Please login', 'Warning');
            this.router.navigate(['/Login']);
        }
        if (!this.quantity || this.quantity === 0) {
            this.generalService.ShowAlert('Warning', 'Please select Quantity before placing the order.', 'Warning');
            return; // Stop further execution
        }
        if (!this.orderitem || this.orderitem.length === 0 ) {
            this.generalService.ShowAlert('Warning',  'Please select Size before placing the order.', 'Warning');
            return; // Stop further execution
        }

        debugger
        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();

            this.arr.push({
                CustomerID: this.loginDetails[0].RegID,
               // AID: this.AID,
                PaymentType: "OnlinePayment",
                PackageID: this.Sname,
                Quantity: this.quantity,               
                Price: this.TPrice,
                TransactionID: this.n,
                TotalPrice: this.GrandTotal,
                CreatedBy: this.loginDetails[0].RegID,
                Device: 'Web',
            })
        
        localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
        localStorage.setItem("Itemsarr", JSON.stringify(this.orderitem));
        this.router.navigate(['/CheckOut']);

      
    }

    PlaceOrder12() {

        debugger

        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();

        for (var i = 0; i < this.cartItems.length; i++) {
            this.arr.push({
                CustomerID: this.loginDetails[0].RegID,
                AID: this.AID,
                PaymentType: "OnLinePayment",
                Quantity: this.cartItems[i].Quantity,
                Size: this.cartItems[i].Size,
                Price: this.cartItems[i].Price,
                PackageID: this.cartItems[i].PackageID,
                TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
                CreatedBy: this.loginDetails[0].RegID,
                Device: 'Web'
            })
        }
        localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
        this.router.navigate(['/CheckOut']);
      
    }

    getPaymentType(pType: string) {
        this.PaymentType = 'CasonDelivery'
    }

    getproducts() {
        debugger

        var UploadFile = new FormData()

        UploadFile.append("Param", this.Sname);
        var url = "api/DressBox/GetPackagesbased_on_PackageId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
             //   this.generalService.ShowAlert('No products', 'No Products  ', 'Warning');
            } else {
                debugger
                this.Productdata = data;
                this.Productid = this.Productdata[0].Productid
                this.UPID = this.Productdata[0].UPID
                this.ProductInfo = null;
                this.Price = this.Productdata[0].Price
                this.TPrice = this.Productdata[0].Price
                this.PackageDiscount = this.Productdata[0].PackageDiscount
                this.PakageName = this.Productdata[0].PakageName
                this.Image = this.Productdata[0].Image
                
                this.GenderType = this.Productdata[0].GenderType
                //this.Description = decodeURIComponent(this.Productdata[0].ProductInfo)
                this.Description = decodeURIComponent(this.Productdata[0].Description)

            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }
   
   
    Sizename(Name) {
        debugger
        this.sizeitem = Name
    }
    navigateproduct(val: any) {
        debugger
        this.navigateservice.setStatusId(val);
        this.router.navigate(['/ProductDetails',val]);
    }

    getRproducts() {
        debugger
        var SchoolID = localStorage.getItem("SchoolID");
        var UploadFile = new FormData()
        UploadFile.append("Param", SchoolID);
        var url = "api/DressBox/RProducts";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger                           
            this.RProductdata = data;
            localStorage.removeItem("SchoolID");
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint45', 'error');
        });
    }

    



    getCustomerAddress() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegID);
        var url = "api/DressBox/Get_addresses_based_Userid";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.addressDetails = data;
                this.plus = false
            }
        });
    }

 

    logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }
  

    navigatingtoCategory(data) {
        debugger
        this.Sname = data
        this.router.navigate(['/Packages/' + data]);
        
    }

  
    
}
