
import { Component, ViewChild, TemplateRef, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

declare var $: any;

import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    
    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;

    public form: FormGroup;
   

    arr: any = [];
    isLoggedIn: boolean = false;
    logindata: any;
    logindeta; any;
    Username: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    Mobile: any;
    randomnumber: any;Remember: boolean = false;
    //Password: any;
    public email: AbstractControl;
    public Password: AbstractControl;
    passwordshow: boolean = false; schooldata1: any;
    schooldata: any; ww: any;
    ids: any; UserData: any;
    selecteduser: any; logindet: any;
    cookieEmailValue: string;
    cookiePWDValue: string;
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private generalservice: GeneralserviceService, private cookieService: CookieService, public http: HttpClient, private fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private elementRef: ElementRef, private renderer: Renderer2 ) {
        debugger
        this.selecteduser = JSON.parse(localStorage.getItem('selectedUser'));
        this.logindet = JSON.parse(localStorage.getItem('LoginDetails'));
        this.ids = this.activatedRoute.snapshot.paramMap.get("Id")
        if (this.ids == 1) {
           // this.OnSubmit();
        }
        this.logindeta = localStorage.getItem('Logindetals');
        this.form = this.fb.group({
            Mobile: ['',],
            Password: ['',],
            email: ['',],
           // rememberMe: [false]
        })

        const cookieExists: boolean = cookieService.check('RememberMe');
        //if (cookieExists) {
        //  //  this.cookieEmailValue = this.cookieService.get('email');
        //  //  this.cookiePWDValue = this.cookieService.get('Password');


        //    this.Remember = true;

        //}
        //else {
        //    this.form.reset();
        //}
    
        this.email = this.form.controls['email'];
        this.Password = this.form.controls['Password'];
       // this.Remember = true;
    }
    
  

    ngOnInit() {
        this.selecteduser = JSON.parse(localStorage.getItem('selectedUser'));
        const loginDetails = JSON.parse(localStorage.getItem('LoginDetails') || '{}');
        this.isLoggedIn = loginDetails.isLoggedIn || false;
        this.Username = loginDetails.Username || null;
        this.loadRememberedCredentials();
           
        }

    loadRememberedCredentials() {
        const rememberMeChecked: string = this.cookieService.get('RememberMe');
        if (rememberMeChecked === 'Checked') {
            this.Remember = true;
            this.form.patchValue({
                email: this.cookieService.get('email'),
                Password: this.cookieService.get('Password')
            });
        }
    }

   
       // this.selectCard(users);
        
    
    openModal() {
        // Open the modal
        // You can implement your logic here to display the modal
        // For example, you can set a boolean flag to true
    }
    logout() {

        this.isLoggedIn = false;
        this.Username = null;
        localStorage.removeItem('LoginDetails');
        localStorage.removeItem('selectedUser');
    }
   

    Close() {
        const modal4 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal4, 'display', 'none');
        const modal5 = this.elementRef.nativeElement.querySelector('#myModal3');
        this.renderer.setStyle(modal5, 'display', 'none');
        const modal6 = this.elementRef.nativeElement.querySelector('#myModal4');
        this.renderer.setStyle(modal6, 'display', 'none');
    }

    public OnSubmit(): void {
        debugger
        this.arr = [];
        this.arr.push({
            Mobile: this.form.value.email,
            Password: this.form.value.Password,
        });

        if (this.form.valid) {
            var uploadfile = new FormData;
            uploadfile.append("Param1", this.form.value.email);
            uploadfile.append("Param2", this.form.value.Password);
            var url = "api/DressBox/DB_Admin_Login";
            this.generalservice.PostData(url, uploadfile).then((data: any) => {
                debugger
                if (data != null) {
                    if (data == "Invalid User") {
                     this.generalservice.ShowAlert("Warning", 'Please enter valid credentials, If you are a new user please sign up', 'Warning');                       
                    //    this.router.navigate(['/login']);
                        this.isLoggedIn = false;
                    } if (data.length > 1) {
                        this.UserData = data;
                        
                    }
                    else {                     
                        localStorage.setItem("LoginDetails", JSON.stringify(data));
                        this.logindet = data;
                        if (this.ids == 1) {
                            debugger
                            this.router.navigate(['/Packages/' + this.logindet[0].schoolname]).then(() => {
                                window.location.reload(); // Reload the page after navigation
                            });
                        }
                        if (this.ids != 1) {
                            this.router.navigate(['/Packages/' + this.logindet[0].schoolname]).then(() => {
                                window.location.reload(); // Reload the page after navigation
                            });
                        }
                    }
                } else {
                    // Handle the case where data is null
                    this.generalservice.ShowAlert("Warning", 'Please enter valid credentials, If you are a new user please sign up', 'Warning');
                    window.location.reload();
                    this.isLoggedIn = false;
                }
            }, (err: any) => {
                this.generalservice.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
                this.isLoggedIn = false;
            });
        }
    }

    RememberMe(ev: any) {

        if (ev.target.checked) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.email);
            this.cookieService.set('Password', this.form.value.Password);
            this.cookieService.set('RememberMe', 'Checked');
        } else {
            this.Remember = false;
            this.cookieService.set('RememberMe', 'UnChecked');
            this.cookieService.deleteAll();
        }
    }

    keepmesignin(e: any) {
        debugger;
        var a = e.target.checked;
        if (a == true) {
            localStorage.setItem("keepsignin", a);
        }
        else {
            localStorage.removeItem("keepsignin");
        }
    }

    ForgotPassword() {
        debugger
        this.Mobile = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'block');
    }

    CheckMobile(Mobile) {
        debugger;

        Mobile: Mobile

        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Email")
            // this.openToast();
        }
        else {

            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            //UploadFile.append("Flag", "1");
            var url = "api/DressBox/checking_Mobile"
            debugger;
            this.generalservice.PostData(url, UploadFile).then(data => {
                debugger;
                if (data != 'NOTEXIST') {
                    debugger
                    this.OTP = ""
                    this.Mobile = Mobile;
                    this.SendOtpForMobile();
                    const modal2 = this.elementRef.nativeElement.querySelector('#myModal2');
                    this.renderer.setStyle(modal2, 'display', 'none');

                    // Show myModal3
                    const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal3, 'display', 'block');

                }
                else {

                    alert("Please Enter Valid Email Id")
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        debugger

        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Forgot Password OTP is:" + this.randomnumber + " Dress Box";
        //var DLTTEID = '1207165215802728246';

        var UploadFile = new FormData();
        UploadFile.append("EMailID", this.Mobile);
        UploadFile.append("Content", msg);        
        var url = 'api/DressBox/SendOTPtoMail';
        this.generalservice.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber == otp) {
            const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
            this.renderer.setStyle(modal3, 'display', 'none');

            const modal = this.elementRef.nativeElement.querySelector('#myModal4');
            this.renderer.setStyle(modal, 'display', 'block');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/



    /*Change password*/
    ChangePassword(Password, confirmpassword) {
        if (Password == undefined || Password == null || Password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (Password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Password", Password);
                UploadFile.append("Mobile", this.Mobile);
                var url = "api/DressBox/Forgot_Password"
                debugger;
                this.generalservice.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        var uploadfile = new FormData;
                        uploadfile.append("Param1", this.Mobile);
                        uploadfile.append("Param2", Password);
                        var url = "api/DressBox/DB_Admin_Login";
                        this.generalservice.PostData(url, uploadfile).then(data => {
                            if (data == "Invalid User") {
                                this.generalservice.ShowAlert("Warning", 'Please enter valid credentials, If you are a new user please sign up', 'Warning');
                                this.isLoggedIn = false;
                            } else {
                                localStorage.setItem("LoginDetails", JSON.stringify(data));
                                if (this.ids == 1) {
                                    debugger
                                    this.router.navigate(['/AddToCart'])
                                }
                                if (this.ids != 1) {
                                    this.router.navigateByUrl('/navbar').then(() => {
                                        this.router.navigate(['/CarTypes']).then(() => {
                                            window.location.reload();
                                        });

                                    });
                                }
                            }
                        })
                        this.generalservice.ShowAlert('SUCCESS', 'Password Changed Successfully.', 'success')
                        const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                        this.renderer.setStyle(modal, 'display', 'none');
                    }
                }, err => {
                    const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                    this.renderer.setStyle(modal, 'display', 'none');
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }

 

    login() {
        debugger;
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.selecteduser.AdmissionNo);
        uploadfile.append("Param2", this.selecteduser.Password);
        var url = "api/DressBox/DB_Admin_Login";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger;
            localStorage.setItem("LoginDetails", JSON.stringify(data));
            this.getschool();
            this.router.navigate(['/Packages/' + this.selecteduser.schoolname]).then(() => {
                window.location.reload(); // Reload the page after navigation
            });

        });
    }

    selectCard(users: any) {
        debugger;
        this.selecteduser = users; // Assign the selected user to selecteduser
        localStorage.setItem('selectedUser', JSON.stringify(users));
        this.login();
        // You can do other actions here if needed

    }
    getschool() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.selecteduser.SchoolId);
        var url = "api/DressBox/Get_Schools_basedOn_SchoolId";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.schooldata1 = data;
            this.ww = this.schooldata1.filter(a => a.CID == 1)
            this.schooldata = this.ww
        }, err => {
            this.generalservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')
        })
    }

    //getschool() {
    //    debugger
    //    var uploadfile = new FormData();
    //    uploadfile.append("Param", this.loginDet[0].ClassId);
    //    var url = "api/DressBox/Get_SchoolProducts_BasedON_ClassId";
    //    this.generalService.PostData(url, uploadfile).then((data: any) => {
    //        debugger
    //        if (data.length === 0) {
    //            this.generalService.ShowAlert("Warning", 'No Products in this school', 'warning');
    //        } else {
    //            this.schooldata = data;
    //            this.schoolId = this.schooldata[0].Brandid
    //        }
    //    }, err => {
    //        this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
    //    });
    //}

}







