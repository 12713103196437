import { Component } from '@angular/core';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { from } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    loginDet: any;
    profiledata: any;
    schoolname: any; AdmissionNo: any;
    SyllabusType: any; ClassName: any;
    Fullname: any; Email: any; Mobile: any;


    constructor(public generalService: GeneralserviceService, public router: Router) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    }

    ngOnInit(): void {
        this.Profiledetail();

    }

      Profiledetail() {
        debugger
        var uploadfile = new FormData();
          uploadfile.append("Param", this.loginDet[0].RegID);
        var url = "api/DressBox/Get_Profile_Details";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.profiledata = data;
            this.Fullname = this.profiledata[0].Name;
            this.Mobile = this.profiledata[0].Mobile
            this.Email = this.profiledata[0].Email
            this.ClassName = this.profiledata[0].ClassName
            this.SyllabusType = this.profiledata[0].SyllabusType
            this.schoolname = this.profiledata[0].schoolname
            this.AdmissionNo = this.profiledata[0].AdmissionNo

        })

    }

}
